(function ($) { 
    $(function() {
        var nav = $('#nav-list');
        var button = $('<a href="#" id="menu-toggle" class="menu-toggle">Menu</a>');

        $('#nav-toggle').append(button);
        nav.addClass('nav-hidden');

        button.on('click', function (e) {
            e.preventDefault();
            nav.toggleClass('nav-hidden');
        });
    });
})(jQuery);